var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Location Name")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Address Line 1")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("City")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Postcode")
              ]),
              _c("gov-table-header", { attrs: { scope: "col", right: "" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm._l(_vm.serviceLocations, function(serviceLocation) {
            return _c(
              "gov-table-row",
              { key: serviceLocation.id },
              [
                _c("gov-table-cell", [
                  _vm._v(_vm._s(serviceLocation.name || "-"))
                ]),
                _c("gov-table-cell", [
                  _vm._v(_vm._s(serviceLocation.location.address_line_1))
                ]),
                _c("gov-table-cell", [
                  _vm._v(_vm._s(serviceLocation.location.city))
                ]),
                _c("gov-table-cell", [
                  _vm._v(_vm._s(serviceLocation.location.postcode))
                ]),
                _c(
                  "gov-table-cell",
                  { attrs: { right: "" } },
                  [
                    _c(
                      "gov-link",
                      {
                        attrs: {
                          to: {
                            name: "service-locations-show",
                            params: { serviceLocation: serviceLocation.id }
                          }
                        }
                      },
                      [_vm._v("View")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.serviceLocations.length === 0
            ? _c(
                "gov-table-row",
                [
                  _c(
                    "gov-table-cell",
                    { attrs: { center: "", colspan: "5" } },
                    [_vm._v("No locations for this service")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }